import React, {useEffect, useState} from 'react'

import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Tooltip
} from '@mui/material'
import {useTranslation} from "react-i18next"
import {useNavigate, useLocation} from 'react-router-dom'

import AppLayout from '../../components/Layouts/AppLayout'
import TableShared from '../../components/TableShared'
import {useStateContext} from '../../context/ContextProvider'
import {useAuth} from '../../hooks/auth'
import axios from '../../lib/axios'
import ClearIcon from "@mui/icons-material/Clear"
import update from 'immutability-helper'
import Loading from '../../components/Loading'

import {handleDeleteSelectionObject, handleDeleteSelectionPrimitive, isValid} from '../../helpers/helper'

const ProceededShipments = () => {

    const { t } = useTranslation()
    const { config, pusher, choosesite } = useStateContext()
    const { user } = useAuth({ middleware: 'guest' })


    const [isLoading, setIsLoading] = useState(false)
    const [salesOrders, setSalesOrders] = useState([])

    /* search options */
    const [searchShipToNames, setSearchShipToNames] = useState([])
    const [searchStatuses, setSearchStatuses] = useState([])
    const [searchReqDates, setSearchReqDates] = useState([])

    /* states */
    const [searchNo, setSearchNo] = useState(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            return filter[0]?.customer_portal_proceeded_shipments_no || ''
        } else {
            return ''
        }
    })
    //const [searchStatus, setsearchStatus] = useState('')
    const [selectedStatuses, setSelectedStatuses] = useState([])
    const everyStatusSelected = searchStatuses.length > 0 && selectedStatuses.length === searchStatuses.length
    //const [searchShipToName, setSearchShipToName] = useState('')
    const [selectedShipToNames, setSelectedShipToNames] = useState([])
    const everyShipToNameSelected = searchShipToNames.length > 0 && selectedShipToNames.length === searchShipToNames.length
    const [selectedReqDates, setSelectedReqDates] = useState([])
    const everyReqDateSelected = searchReqDates.length > 0 && selectedReqDates.length === searchReqDates.length

    const [openShipToNames, setOpenShipToNames] = useState(false)
    const [openStatuses, setOpenStatuses] = useState(false)
    const [openReqDates, setOpenReqDates] = useState(false)

    /* effects */

    useEffect(() => {
        getShipments()
    }, [])

    useEffect(() => {
        if (isValid(choosesite)) {
            const shipmentChannelCreate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
            const shipmentChannelDelete = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
            const shipmentChannelUpdate = pusher.subscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)

            // Bind shipment channel pushes

            shipmentChannelCreate.bind(`${localStorage.getItem('client_id')}-salesorder-created-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventShipment(data.id, 'created') */
                    getShipments()
                }
            })

            shipmentChannelDelete.bind(`${localStorage.getItem('client_id')}-salesorder-deleted-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventShipment(data.id, 'deleted') */
                    getShipments()
                }
            })

            shipmentChannelUpdate.bind(`${localStorage.getItem('client_id')}-salesorder-updated-event-site-${choosesite}`, data => {
                if (Number(data.customer_id) == Number(user?.customer_id)) {
                    /* handleEventShipment(data.id, 'updated') */
                    getShipments()
                }
            })
        }

        return (() => {
            if (isValid(choosesite)) {
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-created-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-deleted-site-${choosesite}`)
                pusher.unsubscribe(`${localStorage.getItem('client_id')}-salesorder-updated-site-${choosesite}`)
            }
        })
    }, [choosesite])

    useEffect(() => {
        setSearchOptions(salesOrders)
    }, [salesOrders])

    useEffect(() => {
        if (localStorage.getItem('filters') !== null) {
            let filter = JSON.parse(localStorage.getItem('filters'))
            setSelectedStatuses(filter[0]?.customer_portal_proceeded_shipments_status || [])
            setSelectedShipToNames(filter[0]?.customer_portal_proceeded_shipments_location || [])
            setSelectedReqDates(filter[0]?.customer_portal_proceeded_shipments_date || [])
        }
    }, [searchStatuses, searchShipToNames, searchReqDates])

    useEffect(() => {
        createFilterArray(searchNo, selectedStatuses, selectedShipToNames, selectedReqDates)
    }, [searchNo, selectedStatuses, selectedShipToNames, selectedReqDates])

    /* columns */

    const columns = [
        {
            field: 'so_number',
            headerName: t('sales_order_no'),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'requested_delivery_date',
            headerName: t('req_date'),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'shipment_date',
            headerName: t('shipment_date'),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'so_status_name',
            headerName: t('status'),
            minWidth: 200,
            flex: 1
        },
        {
            field: 'ship_address_name',
            headerName: t('ship_to_name'),
            minWidth: 200,
            flex: 1
        },
        {
            field: 'actions',
            headerName: t('actions'),
            sortable: false,
            minWidth: 140,
            flex: 1,
            cellClassName: 'padding-0',
            renderCell: (params) => <TodaysSalesOrderAction params={params} setIsLoading={setIsLoading} config={config} />
        }
    ]

    /* functions */
    const getShipments = async () => {
        setIsLoading(true)

        await axios
            .get(`/api/list-orders?order_type=sales&?customer_id=${user?.customer_id}&include_broker`, config)
            .then(res => {
                const data = res.data?.data
                setSalesOrders(data)
            }).catch(({ response }) => {
                console.error(response)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const setSearchOptions = (shipments) => {
        if (shipments && shipments.length > 0) {
            const statuses = []
            const shipToNames = []
            const reqDates = []

            shipments.forEach(shipment => {
                const newStatus = {
                    id: shipment.so_status_id,
                    name: shipment.so_status_name,
                };

                const newShipToName = {
                    id: shipment.ship_address_id,
                    name: shipment.ship_address_name,
                };

                const newReqDate = shipment.requested_delivery_date

                // Check if status exists already as an option
                const newStatusExists = statuses.some(function (status) {
                    return status.id === newStatus.id
                });

                if (!newStatusExists) {
                    statuses.push(newStatus);
                }

                // Check if ship to address exists already as an option
                const newShipToNameExists = shipToNames.some(function (shipToName) {
                    return shipToName.id === newShipToName.id
                });

                if (!newShipToNameExists) {
                    shipToNames.push(newShipToName);
                }

                // Check if requested delivery date already exists as an option
                const newReqDateExists = reqDates.some(function (reqDate) {
                    return reqDate === newReqDate
                })

                if (!newReqDateExists) {
                    reqDates.push(newReqDate)
                }
            })

            statuses.sort((a, b) => {
                return a.id - b.id
            });

            shipToNames.sort((a, b) => {
                return a.id - b.id
            });

            reqDates.sort((a, b) => {
                return a - b
            })

            setSearchStatuses(statuses);
            setSearchShipToNames(shipToNames);
            setSearchReqDates(reqDates)
        }
    }

    const handleChangeShipToNamesSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((shipToName) => {
            if (duplicateRemoved.findIndex((o) => o.id === shipToName.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === shipToName.id)
            } else {
                duplicateRemoved.push(shipToName)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedShipToNames(selectedShipToNames.length === searchShipToNames.length ? [] : searchShipToNames)
            return
        }

        setSelectedShipToNames(duplicateRemoved)
    }

    const handleChangeStatusesSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((status) => {
            if (duplicateRemoved.findIndex((o) => o.id === status.id) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x.id === status.id)
            } else {
                duplicateRemoved.push(status)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedStatuses(selectedStatuses.length === searchStatuses.length ? [] : searchStatuses)
            return
        }

        setSelectedStatuses(duplicateRemoved)
    }

    const handleChangeReqDatesSelection = (event) => {
        const { target: { value } } = event
        let duplicateRemoved = []

        value?.forEach((reqDate) => {
            if (duplicateRemoved.findIndex((o) => o === reqDate) >= 0) {
                duplicateRemoved = duplicateRemoved.filter((x) => x === reqDate)
            } else {
                duplicateRemoved.push(reqDate)
            }
        })

        if (value[value.length - 1] === 'all') {
            setSelectedReqDates(selectedReqDates.length === searchReqDates.length ? [] : searchReqDates)
            return
        }

        setSelectedReqDates(duplicateRemoved)
    }

    const createFilterArray = (no, status, location, date) => {
        if (localStorage.getItem('filters') === null) {
            let filter = [{}]
            localStorage.setItem('filters', JSON.stringify(filter))
        }

        let filters = JSON.parse(localStorage.getItem('filters'))

        filters[0].customer_portal_proceeded_shipments_no = no
        filters[0].customer_portal_proceeded_shipments_status = status
        filters[0].customer_portal_proceeded_shipments_location = location
        filters[0].customer_portal_proceeded_shipments_date = date

        localStorage.setItem('filters', JSON.stringify(filters))
    }

    /* filters */

    const items = salesOrders?.filter(data => {
        if (searchNo === null || searchNo === '')
            return data
        if (data.so_number?.toLocaleLowerCase().includes(searchNo.toLocaleLowerCase()))
            return data
    })

    const filtered = items?.filter(data => {
        if (selectedShipToNames.length < 1)
            return data
        const selectedShipToNameIds = selectedShipToNames.map((shipToName) => shipToName.id)
        if (selectedShipToNameIds.includes(Number(data.ship_address_id)))
            return data
    })

    const reFiltered = filtered?.filter(data => {
        if (selectedStatuses.length < 1)
            return data
        const selectedStatusIds = selectedStatuses.map((status) => status.id)
        if (selectedStatusIds.includes(Number(data.so_status_id)))
            return data
    })

    const filteredByReqDates = reFiltered?.filter(data => {
        if (selectedReqDates.length < 1) return data
        if (selectedReqDates.includes(data.requested_delivery_date)) return data
    })

    return (
        <>
            {isLoading ? <Loading /> : ''}
            <AppLayout>
                <div className='flex justify-between'>
                    <div className='p-5 w-full'>
                        <div className='pb-5 bg-white mb-2 rounded-md'>

                            <div className='flex justify-start gap-4 items-center p-5 border-b'>
                                <p style={{ fontWeight: 600, fontSize: '16px' }}>{t('shipments')}</p>
                            </div>
                            <div className='flex justify-between items-end lg:flex-row flex-col w-full pb-5 border-b'>


                                <div className='px-5 pt-5 w-full'>
                                    <div className='flex justify-between items-center search'>
                                        <input type="text" placeholder={t('search_by_sales_no')} disabled={isLoading} value={searchNo} className='w-full bg-[#f5f5f5] border-0 focus:ring-0 p-0 font-bold place' onChange={(e) => setSearchNo(e.target.value)} />
                                        <i className="fa-solid fa-magnifying-glass" style={{ color: 'rgba(0,0,0,.54)' }}></i>
                                    </div>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openStatuses}>
                                        <InputLabel id="select-statuses-label">{t('search_by_status')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-statuses"
                                            labelId='select-statuses-label'
                                            open={openStatuses}
                                            onOpen={() => setOpenStatuses(true)}
                                            onClose={() => setOpenStatuses(false)}
                                            disabled={isLoading}
                                            value={selectedStatuses}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`s_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionObject(e, x.id, selectedStatuses, setSelectedStatuses, setOpenStatuses)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={handleChangeStatusesSelection}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedStatuses.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedStatuses ? (<IconButton sx={{ visibility: (selectedStatuses.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedStatuses([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchStatuses?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyStatusSelected} indeterminate={selectedStatuses.length > 0 && selectedStatuses.length < searchStatuses.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                searchStatuses?.map((status) =>
                                                    <MenuItem value={status} key={`s${status.id}`}>
                                                        <Checkbox checked={selectedStatuses?.findIndex((i) => i.id === status.id) >= 0} />
                                                        <ListItemText primary={status.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openShipToNames}>
                                        <InputLabel id="select-ship-to-names-label">{t('search_by_ship_to_address')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-ship-to-names"
                                            labelId='select-ship-to-names-label'
                                            open={openShipToNames}
                                            onOpen={() => setOpenShipToNames(true)}
                                            onClose={() => setOpenShipToNames(false)}
                                            disabled={isLoading}
                                            value={selectedShipToNames}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`sta_option-${x.id}`}
                                                            label={x.name}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionObject(e, x.id, selectedShipToNames, setSelectedShipToNames, setOpenShipToNames)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={handleChangeShipToNamesSelection}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedShipToNames.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedShipToNames ? (<IconButton sx={{ visibility: (selectedShipToNames.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedShipToNames([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchShipToNames?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyShipToNameSelected} indeterminate={selectedShipToNames.length > 0 && selectedShipToNames.length < searchShipToNames.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                searchShipToNames?.map((shipToName) =>
                                                    <MenuItem value={shipToName} key={`stn${shipToName.id}`}>
                                                        <Checkbox checked={selectedShipToNames?.findIndex((i) => i.id === shipToName.id) >= 0} />
                                                        <ListItemText primary={shipToName.name} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className='px-5 pt-5 w-full'>
                                    <FormControl sx={{ width: 'inherit' }} focused={openReqDates}>
                                        <InputLabel id="select-requested-delivery-dates-label">{t('search_by_requested_delivery_date')}</InputLabel>
                                        <Select
                                            multiple
                                            id="select-requested-delivery-dates"
                                            labelId='select-requested-delivery-dates-label'
                                            open={openReqDates}
                                            onOpen={() => setOpenReqDates(true)}
                                            onClose={() => setOpenReqDates(false)}
                                            disabled={isLoading}
                                            value={selectedReqDates}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((x) => (
                                                        <Chip
                                                            key={`rdd_option-${x}`}
                                                            label={x}
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            onDelete={(e) => handleDeleteSelectionPrimitive(e, x, selectedReqDates, setSelectedReqDates, setOpenReqDates)}
                                                        />
                                                    ))}
                                                </Box>
                                            )}
                                            onChange={handleChangeReqDatesSelection}
                                            sx={{
                                                boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }, background: '#F5F5F5', borderRadius: '6px',
                                                ".MuiSelect-iconOutlined": { display: (selectedReqDates.length > 0) ? 'none !important' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'rgba(0,0,0,.42)' }
                                            }}
                                            endAdornment={selectedReqDates ? (<IconButton sx={{ visibility: (selectedReqDates.length > 0) ? "visible" : "hidden", padding: '0' }} onClick={() => setSelectedReqDates([])}><ClearIcon /></IconButton>) : false}
                                        >
                                            {
                                                searchReqDates?.length > 0 ?
                                                    <MenuItem value='all'>
                                                        <ListItemIcon>
                                                            <Checkbox checked={everyReqDateSelected} indeterminate={selectedReqDates.length > 0 && selectedReqDates.length < searchReqDates.length} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={t('select_all')} />
                                                    </MenuItem>
                                                    :
                                                    <MenuItem value='nooptions' disabled sx={{ textDecoration: 'italic' }}>{t('no_options')}</MenuItem>

                                            }
                                            {
                                                searchReqDates?.map((reqDate) =>
                                                    <MenuItem value={reqDate} key={reqDate}>
                                                        <Checkbox checked={selectedReqDates?.findIndex((i) => i === reqDate) >= 0} />
                                                        <ListItemText primary={reqDate} />
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='pt-3'>
                                <TableShared columns={columns} items={filteredByReqDates} />
                            </div>
                        </div>
                    </div>
                </div>
            </AppLayout>
        </>
    )
}

export default ProceededShipments

const TodaysSalesOrderAction = (params) => {

    const { id, uploaded_bol, uploaded_driver_bol, broker_transaction } = params.params.row

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const getPodUrl = (
        stream = 'view',
        party = 'customer'
    ) => {
        if(uploaded_driver_bol)
            return uploaded_driver_bol?.document_url

        let urlStem = `sales-orders/${id}`

        if(broker_transaction) {
            if(broker_transaction?.uploaded_bol)
                return broker_transaction.uploaded_bol.document_url

            urlStem = `broker-transactions/${broker_transaction?.id}`
        } else {
            if(uploaded_bol)
                return uploaded_bol.document_url
        }

        const urlRoot = `${process.env.REACT_APP_BACKEND_URL}/api`
        const urlLeaves = `proof-of-delivery?stream=${stream}&party=${party}&CLIENT_ID=${localStorage.getItem('client_id')}&CLIENT_TIMEZONE=${encodeURIComponent(localStorage.getItem('client_timezone'))}`

        return `${urlRoot}/${urlStem}/${urlLeaves}`
    }

    return (
        <>
            <div className='flex justify-between'>
                <Tooltip disableInteractive title={t('open')} placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <button onClick={() => navigate(`/sales-order/${id}`, { state: { prevPathname: location.pathname } })} ><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-eye"></i></span></button>
                    </div>
                </Tooltip>

                <Tooltip disableInteractive title="BOL/POD" placement='bottom'>
                    <div style={{ color: 'rgba(0,0,0,.54)' }}>
                        <a href={getPodUrl()} target="_blank"><span style={{ cursor: 'pointer' }} className="flex justify-center items-center hover:rounded-full icons p-2 hover:bg-zinc-200"><i className="fa-solid fa-print"></i></span></a>
                    </div>
                </Tooltip>
            </div>
        </>
    )
}
