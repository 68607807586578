import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from '../hooks/auth'
import axios from '../lib/axios'
import Pusher from 'pusher-js'
import { includes, isNil, split, trim } from 'lodash'
import globalAPI from '../services/api/globalAPI'
import siteAPI from '../services/api/siteAPI'

const StateContext = createContext()

export const ContextProvider = ({ children }) => {
    const { user } = useAuth({ middleware: 'guest' })

    /* state */
    const [currency, setCurrency] = useState(() => {
        if(localStorage.getItem('currency') !== null) {
            return JSON.parse(localStorage.getItem('currency'))
        } else {
            return []
        }
    })
    const [currencyCode, setCurrencyCode] = useState(() => {
        if (localStorage.getItem('currency_code') !== null) {
            return localStorage.getItem('currency_code')
        } else {
            return "USD"
        }
    })
    const [currencySymbol, setCurrencySymbol] = useState(() => {
        if (localStorage.getItem('currency_symbol') !== null) {
            return localStorage.getItem('currency_symbol')
        } else {
            return "$"
        }
    })
    const [companies, setCompanies] = useState([])
    const [sites, setSites] = useState([])
    const [roles, setRoles] = useState([])
    const [allSites, setAllSites] = useState([])
    const [choosesite, setChoosesite] = useState(false)
    const [company_id, setCompany_id] = useState('')
    const [performedFirstTimeRequestRetrieval, setPerformedFirstTimeRequestRetrieval] = useState(false)
    const [extended, setExtended] = useState(true)
    const [hiddenDailyBoard, setHiddenDailyBoard] = useState(true)
    const [hiddenAdminView, setHiddenAdminView] = useState(true)
    const [hiddenUserManagment, setHiddenUserManagment] = useState(true)
    const [hiddenProduction, setHiddenProduction] = useState(true)
    const [hiddenInventory, setHiddenInventory] = useState(true)
    const [total, setTotal] = useState(0)
    const [totalPendingProductionOrders, setTotalPendingProductionOrders] = useState(0)
    const [palletTypes, setPalletTypes] = useState([])
    const [clientType, setClientType] = useState([])
    const [clientIsBroker, setClientIsBroker] = useState(false)
    const [clientIsBrokerOnly, setClientIsBrokerOnly] = useState(false)
    const [features, setFeatures] = useState({})
    const [permissions, setPermissions] = useState([])
    const [logisticsTypes, setLogisticsTypes] = useState([])
    const [creditTerms, setCreditTerms] = useState([])
    const [menuItems, setMenuItems] = useState([])

    const config = {
        headers: {
            Authorization: `Bearer ${user?.plain_text_token}`,
            'Preferred-Language': localStorage.getItem('i18nextLng'),
            'Preferred-Currency': currencyCode
        },
    }

    const configFile = {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${user?.plain_text_token}`,
            'Preferred-Language': localStorage.getItem('i18nextLng'),
            'Preferred-Currency': currencyCode
        },
    }

    useEffect(() => {
        if(user && !isNil(user)) {
            localStorage.setItem('client_id', user?.client_id)
            localStorage.setItem('client_timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)

            const clientType = split(user?.client_type, ',').map((type) => trim(type))
            setClientType(clientType)
            setClientIsBroker(includes(clientType, 'broker'))
            setClientIsBrokerOnly(clientType.length == 1 && includes(clientType, 'broker'))
        }
    }, [user])

    useEffect(() => {
        if (user?.role === "master_admin") {
            getPermissions()
            getCompanies()
        }

        if (includes(['master_admin', 'office_manager'], user?.role)) {
            getRoles()
            getPalletTypes()
            getFeatures()
            getCreditTerms()
        }

        if (includes(['master_admin', 'office_manager', 'vendor_user'], user?.role)) {
            getLogisticsTypes()
        }

        if (user) {
            getAllSites()
            getSites()
            getMenuItems()
        }
    }, [user])

    const getSites = async (companyId) => {
        await siteAPI.getSites(
            config,
            {
                where: {
                    company_id: companyId
                }
            }
        )
            .then((data) => {
                setSites(data)
            })
    }

    const getAllSites = async () => {
        await siteAPI.getSites(
            config,
            {
                with: [
                    'company'
                ]
            }
        )
            .then((data) => {
                setAllSites(data)
            })
    }

    const getCurrency = async (cid) => {
        await axios
            .get(`/api/companies/${cid}`, config)
            .then(res => {
                const data = res.data
                setCurrency(data.currency)
                setCurrencyCode(data.currency_code)
                setCurrencySymbol(data.currency.symbol)
                localStorage.setItem('currency_code', data.currency_code)
                localStorage.setItem('currency_symbol', data.currency.symbol)
                localStorage.setItem('currency', JSON.stringify(data.currency))
            })
            .catch(( error ) => {
                if (error.response.status !== 422) {
                    throw error
                }
            })
    }

    const getMenuItems = async () => {
        await globalAPI.getMenuItems(config)
            .then(( data ) => {
                setMenuItems(data)
            })
    }

    const getCompanies = async () => {
        await globalAPI.getCompanies(config)
            .then(( data ) => {
                setCompanies(data)
            })
    }

    const getRoles = async () => {
        await globalAPI.getRoles(config)
            .then(( data ) => {
                setRoles(data)
            })
    }

    const getPalletTypes = async () => {
        await globalAPI.getPalletTypes(config)
            .then(( data ) => {
                setPalletTypes(data)
            })
    }

    const getFeatures = async () => {
        await globalAPI.getFeatures(config)
            .then(( data ) => {
                setFeatures(data)
            })
    }

    const getPermissions = async () => {
        await globalAPI.getPermissions(config)
            .then(( data ) => {
                setPermissions(data)
            })
    }

    const getLogisticsTypes = async () => {
        await globalAPI.getLogisticsTypes(config)
            .then(( data ) => {
                setLogisticsTypes(data)
            })
    }

    const getCreditTerms = async () => {
        await globalAPI.getCreditTerms(config)
            .then(( data ) => {
                setCreditTerms(data)
            })
    }

    const pusher = new Pusher(process.env.REACT_APP_PUSHER, {
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
    })
    /* Pusher.logToConsole = true */

    return (
        <StateContext.Provider value={{
            pusher, configFile, user, companies,
            config, sites, getSites, roles, allSites, choosesite, setChoosesite,
            company_id, setCompany_id, getCompanies, currency, currencyCode, currencySymbol,
            getCurrency, performedFirstTimeRequestRetrieval, setPerformedFirstTimeRequestRetrieval,
            extended, setExtended, hiddenDailyBoard, setHiddenDailyBoard, hiddenAdminView, setHiddenAdminView,
            hiddenUserManagment, setHiddenUserManagment, hiddenProduction, setHiddenProduction, hiddenInventory,
            setHiddenInventory, total, setTotal, totalPendingProductionOrders, setTotalPendingProductionOrders, menuItems,
            palletTypes, clientType, clientIsBroker, clientIsBrokerOnly, features, permissions, logisticsTypes, creditTerms
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)
